<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99974 20C4.92426 20 2.69745 20 1.25176 18.0909C-0.193924 16.1818 4.92428 14.75 7.99976 14.75C11.0752 14.75 16.1942 16.1818 14.7481 18.0909C13.3021 19.9999 11.0752 20 7.99974 20Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.8291 17.8144C15.8912 17.8144 15.9536 17.8144 16.0166 17.8144C18.6527 17.8144 20.5614 17.8143 21.8009 16C23.0404 14.1858 18.6527 12.825 16.0166 12.825C14.2625 12.825 11.7332 13.4275 10.6021 14.3652C13.4965 14.755 16.8516 16.1008 15.8291 17.8144Z"
            fill="white" />
        <ellipse cx="7.99997" cy="8.75" rx="3.88889" ry="3.75" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.7269 9.30576C12.7548 9.12442 12.7693 8.93884 12.7693 8.75C12.7693 8.37045 12.7108 8.00407 12.6021 7.65878C12.8872 6.14614 14.2465 5 15.8804 5C17.7213 5 19.2137 6.45507 19.2137 8.25C19.2137 10.0449 17.7213 11.5 15.8804 11.5C14.4185 11.5 13.1764 10.5824 12.7269 9.30576Z"
            fill="white" />
    </svg>
</template>