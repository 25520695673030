<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.90532 17.3787C3.96096 17.1561 4.16095 17 4.39039 17H7.60961C7.83905 17 8.03904 17.1561 8.09468 17.3787L8.84468 20.3787C8.92358 20.6943 8.6849 21 8.35961 21H3.64039C3.3151 21 3.07642 20.6943 3.15532 20.3787L3.90532 17.3787Z"
            fill="white" />
        <path
            d="M6.90532 12.3787C6.96096 12.1561 7.16095 12 7.39039 12H10.6096C10.839 12 11.039 12.1561 11.0947 12.3787L11.8447 15.3787C11.9236 15.6943 11.6849 16 11.3596 16H6.64039C6.3151 16 6.07642 15.6943 6.15532 15.3787L6.90532 12.3787Z"
            fill="white" />
        <path
            d="M9.90532 17.3787C9.96096 17.1561 10.161 17 10.3904 17H13.6096C13.839 17 14.039 17.1561 14.0947 17.3787L14.8447 20.3787C14.9236 20.6943 14.6849 21 14.3596 21H9.64039C9.3151 21 9.07642 20.6943 9.15532 20.3787L9.90532 17.3787Z"
            fill="white" />
        <path
            d="M12.9053 12.3787C12.961 12.1561 13.161 12 13.3904 12H16.6096C16.839 12 17.039 12.1561 17.0947 12.3787L17.8447 15.3787C17.9236 15.6943 17.6849 16 17.3596 16H12.6404C12.3151 16 12.0764 15.6943 12.1553 15.3787L12.9053 12.3787Z"
            fill="white" />
        <path
            d="M9.90532 7.37873C9.96096 7.15615 10.161 7 10.3904 7H13.6096C13.839 7 14.039 7.15615 14.0947 7.37873L14.8447 10.3787C14.9236 10.6943 14.6849 11 14.3596 11H9.64039C9.3151 11 9.07642 10.6943 9.15532 10.3787L9.90532 7.37873Z"
            fill="white" />
        <path
            d="M15.9053 17.3787C15.961 17.1561 16.161 17 16.3904 17H19.6096C19.839 17 20.039 17.1561 20.0947 17.3787L20.8447 20.3787C20.9236 20.6943 20.6849 21 20.3596 21H15.6404C15.3151 21 15.0764 20.6943 15.1553 20.3787L15.9053 17.3787Z"
            fill="white" />
        <path d="M19.3005 10.3566L21 9.50049" stroke="white" stroke-width="1.5" stroke-linecap="round" />
        <path d="M4.57747 10.572L3 9.50049" stroke="white" stroke-width="1.5" stroke-linecap="round" />
        <path d="M17.0694 6.19853L17.8495 5.30223" stroke="white" stroke-width="1.5" stroke-linecap="round" />
        <path d="M6.80783 6.19853L6.02774 5.30223" stroke="white" stroke-width="1.5" stroke-linecap="round" />
        <path d="M12 4V2" stroke="white" stroke-width="1.5" stroke-linecap="round" />
    </svg>
</template>