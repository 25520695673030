export default function truncateString(str, num = 12) {
    if (typeof str !== 'string' || typeof num !== 'number' || num < 0) {
        return '';
    }

    if (str.length <= num) {
        return str;
    }

    return str.slice(0, num) + '...';
}