import axios from "axios";

export default async function postman(endpoint, initData, dataForServer = null) {
    let url = `${process.env.VUE_APP_API_DOMAIN}${endpoint}`;

    const formData = new FormData();
    formData.append('initData', initData);

    if (dataForServer) {
        for (let key in dataForServer) {
            formData.append(key, dataForServer[key]);
        }
    }

	let headers = {
        'Content-Type': 'multipart/form-data'
    }

	let postman = await axios.post(
		url,
		formData,
		{
			headers: headers
		}
	).catch(error => {
		console.log(error.message)
	});

	if (postman.data) {
		if (postman.data?.error && postman.data.error == 'invalidUser!') {
			return {error: 'invalidUser!'};
		}

		let response = postman.data;

		return response;
	}

	return null;
}