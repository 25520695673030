import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/HomeView.vue')
	},

	{
		path: '/cards/:shop',
		name: 'cards',
		component: () => import('@/views/CardsView.vue')
	},

	{
		path: '/referals',
		name: 'referals',
		component: () => import('@/views/ReferalsView.vue')
	},

	{
		path: '/bonus',
		name: 'bonus',
		component: () => import('@/views/BonusView.vue')
	},

	{
		path: '/airdrop',
		name: 'airdrop',
		component: () => import('@/views/AirdropView.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router