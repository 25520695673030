export default function numberFormat(number, asCurrency = false) {
    if (asCurrency) {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return currencyFormatter.format(number);
    } else {
        const numberFormatter = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2
        });
        return numberFormatter.format(number);
    }
}