import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        userInfoData: {},
        userProgressData: {},
        userAvatarData: null,
        userTapData: {},

        intervalAvailableTapsId: null,

        cardsList: []
    }),

    getters: {
        getUserInfoData(state) {
            return state.userInfoData;
        },
        getUserProgressData(state) {
            return state.userProgressData;
        },
        getUserAvatarData(state) {
            return state.userAvatarData;
        },
        getUserTapData(state) {
            return state.userTapData;
        },
        getCards(state) {
            return state.cardsList;
        }
    },

    actions: {
        saveUserData(value) {
            const { user, progress, avatar, tap } = value;

            this.userInfoData = user;
            this.userProgressData = progress;
            this.userAvatarData = avatar;
            this.userTapData = tap;
        },

        decrementAvailableTaps(decrementAvailableTaps) {
            let amtDecrementTabs = this.userProgressData.tap_profit*decrementAvailableTaps;
            let availableTaps = this.userTapData.availableTaps;

            if (amtDecrementTabs > availableTaps) {
                this.userTapData.availableTaps = 0;
                amtDecrementTabs = availableTaps;
            }else{
                this.userTapData.availableTaps -= amtDecrementTabs;
            }

            this.userProgressData.coin_balance += amtDecrementTabs;
        },

        startIntervalAvailableTaps() {
            if (this.intervalAvailableTapsId) return

            this.intervalAvailableTapsId = setInterval(() => {
                let currentPower = this.userTapData.availableTaps;
                let maxPower = this.userProgressData.dragon_power;

                if (currentPower < maxPower) {
                    let addTap = 3;
                    if ( (maxPower-currentPower) < addTap ) addTap = (maxPower-currentPower);

                    this.userTapData.availableTaps += addTap;
                }
            }, 1500);
        },

        saveCardList(value) {
            this.cardsList = value;
        },

        addNewLevel() {
            this.userProgressData.level += 1;
            this.userProgressData.dragon_power += 500;
        }
    }
})