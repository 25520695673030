<template>
	<template v-if="data_loaded">
		<router-view />
		<Navbar />
	</template>

	<template v-else>
		<loading :lock-scroll="true" :active="true" :is-full-page="true" color="#566eb3" loader="spinner"
			class="preloaderData" :height="72" :width="72" />
	</template>
</template>

<script>
import Navbar from '@/components/NavbarComponent.vue';

import Loading from 'vue3-loading-overlay';
import levelUpCoins from '@/fnc/levelUpCoins.js';

export default {
	components: {
		Navbar,
		Loading
	},

	data: () => ({
		data_loaded: false
	}),

	methods: {
		async getUserData() {
			let api = await this.$postman('user/id.php', this.TWA.initData);
			if (api && api?._success_loaded && api._success_loaded === true) {
				this.$userStore.saveUserData(api);
				this.$userStore.startIntervalAvailableTaps();
			}

			let cards = await this.$postman('user/cards.php', this.TWA.initData, { id: 0 });
			this.$userStore.saveCardList(cards.list);

			if (!this.data_loaded) this.data_loaded = true;
		}
	},

	mounted() {
		if (!this.TWA.isExpanded) {
			this.TWA.expand();
		}

		setTimeout(() => {
			if (!this.TWA.isExpanded) {
				this.TWA.expand();
			}
		}, 1500);

		this.TWA.ready();

		this.getUserData();
	},

	watch: {
		'$userStore.getUserProgressData.coin_balance'(newValue) {
			if (this.$userStore.getUserProgressData.level > 0) {
				if (
					levelUpCoins(this.$userStore.getUserProgressData.level) > 0
					&& newValue >= levelUpCoins(this.$userStore.getUserProgressData.level)
				) {
					this.$userStore.addNewLevel();
				}
			}
		}
	},
}
</script>

<style>
.preloaderData {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 44vh;
}
</style>