export default function abbreviateNumber(num) {
    if (typeof num === 'string') {
        return num;
    }

    let formattedNum;
    if (num >= 1000000000) {
        formattedNum = (num / 1000000000).toFixed(1);
        return formattedNum.endsWith('.0') ? formattedNum.slice(0, -2) + 'B' : formattedNum + 'B';
    } else if (num >= 1000000) {
        formattedNum = (num / 1000000).toFixed(1);
        return formattedNum.endsWith('.0') ? formattedNum.slice(0, -2) + 'M' : formattedNum + 'M';
    } else if (num >= 1000) {
        formattedNum = (num / 1000).toFixed(1);
        return formattedNum.endsWith('.0') ? formattedNum.slice(0, -2) + 'K' : formattedNum + 'K';
    } else {
        return num.toString();
    }
}