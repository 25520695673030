<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.31948 6.04938C4.03797 3.69906 6.85721 1.81892 9.4201 2.75894C11.5321 3.53358 11.922 5.57076 11.9876 6.91244C12.0024 7.21448 11.755 7.45942 11.4527 7.46538C9.00022 7.51375 6.27298 7.79812 5.31948 6.04938Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" />
        <path
            d="M18.6805 6.04938C19.962 3.69906 17.1428 1.81892 14.5799 2.75894C12.4679 3.53358 12.078 5.57076 12.0124 6.91244C11.9976 7.21448 12.245 7.45942 12.5473 7.46538C14.9998 7.51375 17.727 7.79812 18.6805 6.04938Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.25 13.502H5.09511C4.4903 13.502 4 13.9922 4 14.5971V19.8098C4 21.0194 4.98059 22 6.19021 22H11.25V13.502ZM12.75 22H17.8098C19.0194 22 20 21.0194 20 19.8098V14.5971C20 13.9922 19.5097 13.502 18.9049 13.502H12.75V22Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.25 8H4.09511C3.4903 8 3 8.4903 3 9.09511V10.9049C3 11.5097 3.4903 12 4.09511 12H11.25V8ZM12.75 12H19.9049C20.5097 12 21 11.5097 21 10.9049V9.09511C21 8.4903 20.5097 8 19.9049 8H12.75V12Z"
            fill="white" />
    </svg>
</template>