export default function levelUpCoins(level) {
    let minCoins = 0;

    if (level == 0) {
        minCoins = 100;
    }

    if (level == 1) {
        minCoins = 1000;
    }

    if (level == 2) {
        minCoins = 10000;
    }

    if (level == 3) {
        minCoins = 100000;
    }
    
    if (level == 4) {
        minCoins = 1000000;
    }

    if (level == 5) {
        minCoins = 1000000000;
    }

    if (level == 6) {
        minCoins = 10000000000;
    }

    return minCoins;
}